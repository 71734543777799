import { Controller } from '@hotwired/stimulus';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default class extends Controller {
  static targets = ['modal', 'scroll'];
  static values = { open: Boolean };

  connect() {
    if (this.hasModalTarget) {
      this.turboFrame = this.modalTarget.querySelector('turbo-frame');
      this.originalContent = this.turboFrame ? this.turboFrame.innerHTML : '';
      this.updateModalState();
    }
  }

  openValueChanged() {
    if (!this.updatingModal) {
      this.updateModalState();
    }
  }

  toggle() {
    this.openValue = !this.openValue; // This will trigger openValueChanged()
  }

  scrollTargetConnected() {
    disableBodyScroll(this.scrollTarget);
  }

  show() {
    this.updatingModal = true;
    this.openValue = true;
    this.modalTarget.classList.remove('hidden');
    this.dispatch('open');
    this.updatingModal = false;
  }

  hide() {
    this.updatingModal = true;
    this.openValue = false;
    this.modalTarget.classList.add('hidden');
    clearAllBodyScrollLocks();
    this.dispatch('close');

    // Safely reset the src of turbo-frame if it exists
    if (this.turboFrame) {
      this.turboFrame.src = null;
      this.turboFrame.innerHTML = this.originalContent;
    }
    this.updatingModal = false;
  }

  updateModalState() {
    if (this.openValue) {
      this.show();
    } else {
      this.hide();
    }
  }
}
